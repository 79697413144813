<template>

    <body>

        <!-- preloader -->
        <Loader> </Loader>
        <!-- preloader -->
        <div class="frame">
            <div class="site-wrapper overflow-hidden">

                <!-- Header Section -->
                <Affiliate-Header> </Affiliate-Header>

                <!-- manges sales section html code  -->
    <div class="page_main_div">
        <div class="container">
            <div class="row pb-4">
                <div class="col-12 col-md-6">
                    <div class="header">
                        <h1>Manage sales</h1>
                    </div>
                </div>
                <div class="col-12 col-md-6">
                    <div class="search">
                        <input type="text" class="searchTerm" placeholder="Search My History...">
                        <button type="submit" class="searchButton">
                          <i class="fa fa-search"></i>
                       </button>
                     </div>
                </div>
                <div class="linesolid">
                    <hr class="hori">
                </div>
            </div>
            <div class="row addrowProfile">
                <div class="col-12">
                    <div class="header">
                        <h1>Completed Orders</h1>
                    </div>
                    <div class="mani_table_div">
                        <table class="table table-responsive">
                            <thead>
                                <tr class="header-top-table">
                                    <th>
                                        <div class="BUYER">BUYER</div>
                                    </th>
                                    <th>
                                        <div class="GIG">GIG</div>
                                    </th>
                                    <th>
                                        <div class="DUE">DUE NO</div>
                                    </th>
                                    <th>
                                        <div class="DELEVEREDAT">DELEVERED AT</div>
                                    </th>
                                    <th>
                                        <div class="TOTAL">TOTAL</div>
                                    </th>
                                    <th>
                                        <div class="NOTE">NOTE</div>
                                    </th>
                                    <th>
                                        <div class="STATUS">STATUS</div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <div class="user-info">
                                            <div class="user-info__img">
                                                <img src="../../assets/affiliate/payment/image/user1.jpg" alt="User Img">
                                            </div>
                                            <div class="user-info__basic">
                                                <h5 class="mb-0 font-size14">Website22296</h5>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <span class="font-size14">Lorem ipsum dolor sit amet consectetur adipisicing elit.</span>
                                        <div class="font-size14 transprentsFontSize">Donate 101 banana to slum kids</div>
                                    </td>
                                    <td>
                                        <small>Oct 25</small>
                                    </td>
                                    <td>
                                        <small>Oct 22</small>
                                    </td>
                                    <td>
                                        <h6 class="mb-0 font-size14">$ 40</h6>
                                    </td>
                                    <td>
                                        <div class="filesave">
                                            <span class="fa fa-save"></span>
                                            <span class="circle">20</span>
                                        </div>
                                    </td>
                                    <td>
                                        <button type="text" class="btn btn-primary font-size14">COMPLETED</button>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="user-info">
                                            <div class="user-info__img">
                                                <img src="../../assets/affiliate/payment/image/user1.jpg" alt="User Img">
                                            </div>
                                            <div class="user-info__basic">
                                                <h5 class="mb-0 font-size14">Website22296</h5>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <span class="font-size14">Lorem ipsum dolor sit amet consectetur adipisicing elit.</span>
                                        <div class="font-size14 transprentsFontSize">Donate 101 banana to slum kids</div>
                                    </td>
                                    <td>
                                        <small>Oct 25</small>
                                    </td>
                                    <td>
                                        <small>Oct 22</small>
                                    </td>
                                    <td>
                                        <h6 class="mb-0 font-size14">$ 40</h6>
                                    </td>
                                    <td>
                                        <div class="filesave">
                                            <span class="fa fa-save"></span>
                                            <span class="circle">20</span>
                                        </div>
                                    </td>
                                    <td>
                                        <button type="text" class="btn btn-primary font-size14">COMPLETED</button>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="user-info">
                                            <div class="user-info__img">
                                                <img src="../../assets/affiliate/payment/image/user1.jpg" alt="User Img">
                                            </div>
                                            <div class="user-info__basic">
                                                <h5 class="mb-0 font-size14">Website22296</h5>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <span class="font-size14">Lorem ipsum dolor sit amet consectetur adipisicing elit.</span>
                                        <div class="font-size14 transprentsFontSize">Donate 101 banana to slum kids</div>
                                    </td>
                                    <td>
                                        <small>Oct 25</small>
                                    </td>
                                    <td>
                                        <small>Oct 22</small>
                                    </td>
                                    <td>
                                        <h6 class="mb-0 font-size14">$ 40</h6>
                                    </td>
                                    <td>
                                        <div class="filesave">
                                            <span class="fa fa-save"></span>
                                            <span class="circle">20</span>
                                        </div>
                                    </td>
                                    <td>
                                        <button type="text" class="btn btn-primary font-size14">COMPLETED</button>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="user-info">
                                            <div class="user-info__img">
                                                <img src="../../assets/affiliate/payment/image/user1.jpg" alt="User Img">
                                            </div>
                                            <div class="user-info__basic">
                                                <h5 class="mb-0 font-size14">Website22296</h5>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <span class="font-size14">Lorem ipsum dolor sit amet consectetur adipisicing elit.</span>
                                        <div class="font-size14 transprentsFontSize">Donate 101 banana to slum kids</div>
                                    </td>
                                    <td>
                                        <small>Oct 25</small>
                                    </td>
                                    <td>
                                        <small>Oct 22</small>
                                    </td>
                                    <td>
                                        <h6 class="mb-0 font-size14">$ 40</h6>
                                    </td>
                                    <td>
                                        <div class="filesave">
                                            <span class="fa fa-save"></span>
                                            <span class="circle">20</span>
                                        </div>
                                    </td>
                                    <td>
                                        <button type="text" class="btn btn-primary font-size14">COMPLETED</button>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="user-info">
                                            <div class="user-info__img">
                                                <img src="../../assets/affiliate/payment/image/user1.jpg" alt="User Img">
                                            </div>
                                            <div class="user-info__basic">
                                                <h5 class="mb-0 font-size14">Website22296</h5>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <span class="font-size14">Lorem ipsum dolor sit amet consectetur adipisicing elit.</span>
                                        <div class="font-size14 transprentsFontSize">Donate 101 banana to slum kids</div>
                                    </td>
                                    <td>
                                        <small>Oct 25</small>
                                    </td>
                                    <td>
                                        <small>Oct 22</small>
                                    </td>
                                    <td>
                                        <h6 class="mb-0 font-size14">$ 40</h6>
                                    </td>
                                    <td>
                                        <div class="filesave">
                                            <span class="fa fa-save"></span>
                                            <span class="circle">20</span>
                                        </div>
                                    </td>
                                    <td>
                                        <button type="text" class="btn btn-primary font-size14">COMPLETED</button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- manges sales section html code  -->

                <!-- Footer Section -->
                <Front-Footer> </Front-Footer>

            </div>
        </div>

    </body>
</template>

<script>
import VueMeta from 'vue-meta'
import Header from './Affiliate-Header';
import Footer from './Front-Footer';
import inspired from './common/Inspired';
import AffiliateSubscription from './common/Affiliate-Subscription';
import Loader from './Loader';
import Vue from 'vue'
import store from '@/store'
import axios from 'axios';

import { db } from '@/main'


import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
Vue.use(VueMeta)


export default {
    components: {
        'Affiliate-Header': Header,
        'Front-Footer': Footer,
        'Affiliate-Subscription': AffiliateSubscription,
        'Inspired': inspired,
        'Loader': Loader,
        'VueSlickCarousel': VueSlickCarousel


    },
    data() {
        return {
            components: {},
            pages: {},
            slickOptions: {
                //options can be used from the plugin documentation
                slidesToShow: 3,
                infinite: true,
                meta_title: '',
                meta_description: '',
            },
            subscriptionss: [],
        }
    },
    metaInfo() {
        //alert(this.meta_title);
        return {
            title: localStorage.getItem("meta_title"),
            meta: [
                { name: 'description', content: localStorage.getItem("meta_description") },
            ]
        }
    },
    created: function () {
        this.fetchComponents();
        this.fetchPages();
        this.fetchItems();

    },

    methods: {

        fetchItems() {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            this.subscriptionss = [];
            this.slug = this.$route.params.slug;

            db.collection("countries")
                .where("slug", "==", this.slug)
                .get()
                .then((querySnapshot) => {
                    querySnapshot.forEach((docs) => {

                        db.collection("subscriptions")
                            .where("country", "==", docs.id)
                            .get()
                            .then((querySnapshot) => {
                                querySnapshot.forEach((doc) => {

                                    // console.log(doc.id, " => ", doc.data());

                                    this.subscriptionss.push({
                                        id: doc.id,
                                        title: doc.data().title,
                                        image: doc.data().image,
                                    });

                                });
                            })
                            .catch((error) => {
                                console.log("Error getting documents: ", error);
                            });

                    });
                });


            db.collection("components").doc('nlXcHYzVkqDaWGJgixjF')
                .get()
                .then((doc) => {
                    this.components = doc.data();
                })
                .catch((error) => {
                    console.log("Error getting documents: ", error);
                });


        },

        fetchComponents() {

            db.collection("components").doc('ahotUyDbxXzk1h99c29N')
                .get()
                .then((doc) => {
                    console.log('hhhh77');
                    console.log(doc.id, " => ", doc.data());
                    this.components = doc.data();

                })
                .catch((error) => {
                    console.log("Error getting documents: ", error);
                });


        },
        async fetchPages() {

            await db.collection("pages").doc('91T5r0xlt47gme91ObsZ')
                .get()
                .then(async (doc) => {
                    console.log('hhhh88');
                    console.log(doc.id, " => ", doc.data());
                    this.pages = doc.data();
                    this.meta_title = doc.data().meta_title;
                    this.meta_description = doc.data().meta_description;
                    localStorage.setItem("meta_title", this.meta_title);
                    localStorage.setItem("meta_description", this.meta_description);


                })
                .catch((error) => {
                    console.log("Error getting documents: ", error);
                });
            // alert(this.meta_title);

        }
    }
}

</script>
<style>
/* afflialate */

@import "../../assets/affiliate/payment/css/style.css";

@import "../../assets/affiliate/payment/css/responsive.css";

.main-page {
    display: inline-block;
    white-space: nowrap;
    /* width: 409px;
    height: 65px; */
    font-family: Cinzel;
    font-weight: 700;
    font-size: 48px;
    line-height: 90px;
    font-style: normal;
    text-transform: uppercase;
}

.hot-picks-section {
    padding: 10vh 0;
}

.hot-picks-section .section-title {
    text-align: center;
    font-weight: 900;
    margin-bottom: 50px;
}

.hot-picks-section .Card {
    margin: 0 10px;
    position: relative;
}

.hot-picks-section .Card_info {
    position: absolute;
    bottom: 20px;
    left: 20px;
}

.hot-picks-section .Card img {
    border-radius: 8px;
    overflow: hidden;
    height: 400px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    width: 100%;
    object-fit: cover;
}

.hot-picks-section .Card .card-title {
    margin-top: 0;
    font-size: 20px;
    color: #fff;
    margin-bottom: 1px;
}

.hot-picks-section .Card:before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    opacity: 0.3;
    border-radius: 8px;
    background-image: linear-gradient(to top,
            #000000,
            #000000,
            #857d7d00,
            #b9b9b9,
            #ffffff00);
}

.hot-picks-section .Card .card-info {
    font-size: 14px;
    color: #fff;
    margin-bottom: 3px;
    margin-top: 0;
}

@media (max-width:767px) {
    section.hero_section.new-changes {
        height: 84vh;
    }

    section.hero_section img {
        max-height: initial !important;
        height: 100%;
        object-fit: cover;
    }
}
</style>
